import React from 'react'
import Marquee from "react-fast-marquee";
import outsystems from '../assets/Platforms/outsystems.svg'
import appian from '../assets/Platforms/appian.svg'
import salesforce from '../assets/Platforms/salesforce.svg'
import bettyblocks from '../assets/Platforms/bettyblocks.svg'
import buildfire from '../assets/Platforms/buildfire.svg'
import powerapps from '../assets/Platforms/powerapps.svg'
import appmaker from '../assets/Platforms/appmaker.svg'
import mendix from '../assets/Platforms/mendix.svg'
import creator from '../assets/Platforms/creator.svg'
import appypie from '../assets/Platforms/appypie.svg'
import kintone from '../assets/Platforms/kintone.svg'

function Marqone() {
  return (
    <div className="">
        <Marquee speed='30' gradientColor='transparent'>
          <img className='p-4 w-[10.875rem] h-auto' src={outsystems} alt="" />
          <img className='p-4 w-[10.875rem] h-auto' src={appian} alt="" />
          <img className='p-4 w-[10.875rem] h-auto' src={salesforce} alt="" />
          <img className='p-4 w-[10.875rem] h-auto' src={bettyblocks} alt="" />
          <img className='p-4 w-[10.875rem] h-auto' src={buildfire} alt="" />
          <img className='p-4 w-[10.875rem] h-auto' src={powerapps} alt="" />
          <img className='p-4 w-[10.875rem] h-auto' src={appmaker} alt="" />
          <img className='p-4 w-[10.875rem] h-auto' src={mendix} alt="" />
          <img className='p-4 w-[10.875rem] h-auto' src={creator} alt="" />
          <img className='p-4 w-[10.875rem] h-auto' src={appypie} alt="" />
          <img className='p-4 w-[10.875rem] h-auto' src={kintone} alt="" />
        </Marquee>
    </div>
  )
}

export default Marqone
import React from 'react'
import offerings from '../assets/Offerings.png'
import scale1 from '../assets/Scale1.png'

function Offerings ()
{
    return (
        <>
            {/* Nabeel's code */ }

            {/* <section className="pr-20 pl-20 pt-10 w-full m-auto" id='offerings'>
        <div className="flex justify-between">
            <div className="w-1/2 font-DM-Sans">
                <div className="m-10">
                    <h1 className='text-[#ff8400] mb-4'>Offerings</h1>
                    <p className='text-[#293451] font-bold text-4xl mb-8'>Solutions we provide - IT support,<br/> consulting services for businesses</p>
                    <p className='text-[#747984] text-base'>By joining our services, you will have access to cutting-edge technology<br/> and industry-leading expertise, helping you stay ahead of the competition."</p>
                </div>
                <div className="m-10 flex">
                    <div className="basis-1/2">
                        <article className='mb-4'>
                            <img src={scale1} className='w-10 inline-flex align-middle mr-2' alt="" />
                            <h1 className='inline-flex align-top mt-1'>HRM Solutions</h1>
                        </article>
                        <article className='mb-4'>
                            <img src={scale1} className='w-10 inline-flex align-middle mr-2' alt="" />
                            <h1 className='inline-flex align-top mt-1'>Project Management</h1>
                        </article>
                        <article className='mb-4'>
                            <img src={scale1} className='w-10 inline-flex align-middle mr-2' alt="" />
                            <h1 className='inline-flex align-top mt-1'>Helpdesk</h1>
                        </article>
                        <article className='mb-4'>
                            <img src={scale1} className='w-10 inline-flex align-middle mr-2' alt="" />
                            <h1 className='inline-flex align-top mt-1'>CRM</h1>
                        </article>
                    </div>
                    <div className="basis-1/2">
                    <article className='mb-4'>
                            <img src={scale1} className='w-10 inline-flex align-middle mr-2' alt="" />
                            <h1 className='inline-flex align-top mt-1'>Procurement</h1>
                        </article>
                        <article className='mb-4'>
                            <img src={scale1} className='w-10 inline-flex align-middle mr-2' alt="" />
                            <h1 className='inline-flex align-top mt-1'>Asset Management</h1>
                        </article>
                        <article className='mb-4'>
                            <img src={scale1} className='w-10 inline-flex align-middle mr-2' alt="" />
                            <h1 className='inline-flex align-top mt-1'>E-Commerce</h1>
                        </article>
                        <article className='mb-4'>
                            <img src={scale1} className='w-10 inline-flex align-middle mr-2' alt="" />
                            <h1 className='inline-flex align-top mt-1'>SaaS</h1>
                        </article>
                    </div>
                </div>
            </div>
            <div className="w-1/2">
                <div className="m-16">
                    <img src={offerings} alt="" />
                </div>
            </div>
        </div>
                </section> */}

            {/* Hajee's Code */ }

            <div className="flex lg:m-auto sm:justify-evenly !sm:px-16 items-center pb-14 lg:flex-row md:flex-row sm:flex-row xs:flex-col-reverse px-8 m-auto mt-3 max-w-[107.5rem] ">
                <div className='flex justify-center flex-col'>
                    <div className="font-DM-Sans xs:hidden sm:block lg:block mb-4 sm:!min-w-[22.188rem] max-w-[38.75rem] ">
                        <h1 className='lg:font-medium lg:text-base lg:leading-[1.125rem] lg:tracking-[0.12em] sm:text-sm sm:font-medium sm:leading-[0.938rem] sm:tracking-[0.12em] xs:font-medium xs:text-sm xs:leading-[0.938rem] xs:tracking-[0.12em] text-[#ff8400] pb-4 '>Offerings</h1>
                        <p className='lg:font-bold lg:text-4xl lg:leading-[2.688rem] sm:font-bold sm:text-[1.75rem] sm:leading-9 xs:font-medium xs:text-3xl xs:leading-9 text-[#293451]'>
                            {/* Solutions we provide - IT support, consulting services for businesses */ }
                            Solutions we provide - IT infrastructure development and support, consulting services for businesses
                        </p>
                    </div>
                    <div className="xs:mt-[0.625rem] sm:mt-5 md:pt-5 !max-w-[28.125rem] ">
                        <p className='text-[#747984] text-base'>By joining our services, you will have access to cutting-edge technology and industry-leading expertise, helping you stay ahead of the competition."</p>
                        <div className='flex mt-4 ' >
                            <div className="basis-1/2">
                                <article className='mb-4 flex items-center'>
                                    <img src={ scale1 } className='w-6 h-6 inline-flex align-middle mr-2' alt="" />
                                    <h1 className='inline-flex align-top mt-1 xs:font-[400] xs:text-sm xs:leading-[1.125rem] xs:tracking-[-0.01em] sm:text-base text-[#747984]'>Smart Manufacturing Solutions</h1>
                                </article>
                                <article className='mb-4 flex items-center'>
                                    <img src={ scale1 } className='w-6 h-6 inline-flex align-middle mr-2' alt="" />
                                    <h1 className='inline-flex align-top mt-1 xs:font-[400] xs:text-sm xs:leading-[1.125rem] xs:tracking-[-0.01em] sm:text-base text-[#747984]'>Speciallized Healthcare Solutions</h1>
                                </article>
                                <article className='mb-4 flex items-center'>
                                    <img src={ scale1 } className='w-6 h-6 inline-flex align-middle mr-2' alt="" />
                                    <h1 className='inline-flex align-top mt-1 xs:font-[400] xs:text-sm xs:leading-[1.125rem] xs:tracking-[-0.01em] sm:text-base text-[#747984]'>HRM Solutions</h1>
                                </article>
                                <article className='mb-4 flex items-center'>
                                    <img src={ scale1 } className='w-6 h-6 inline-flex align-middle mr-2' alt="" />
                                    <h1 className='inline-flex align-top mt-1 xs:font-[400] xs:text-sm xs:leading-[1.125rem] xs:tracking-[-0.01em] sm:text-base text-[#747984]'>Saas Solutions</h1>
                                </article>
                            </div>
                            <div className="basis-1/2">
                                <article className='mb-4 flex items-center'>
                                    <img src={ scale1 } className='w-6 h-6 inline-flex align-middle mr-2' alt="" />
                                    <h1 className='inline-flex align-top mt-1 xs:font-[400] xs:text-sm xs:leading-[1.125rem] xs:tracking-[-0.01em] sm:text-base text-[#747984]'>Next-Gen Educational Software</h1>
                                </article>
                                <article className='mb-4 flex items-center'>
                                    <img src={ scale1 } className='w-6 h-6 inline-flex align-middle mr-2' alt="" />
                                    <h1 className='inline-flex align-top mt-1 xs:font-[400] xs:text-sm xs:leading-[1.125rem] xs:tracking-[-0.01em] sm:text-base text-[#747984]'>Custom e-Commerce Solutions</h1>
                                </article>
                                <article className='mb-4 flex items-center'>
                                    <img src={ scale1 } className='w-6 h-6 inline-flex align-middle mr-2' alt="" />
                                    <h1 className='inline-flex align-top mt-1 xs:font-[400] xs:text-sm xs:leading-[1.125rem] xs:tracking-[-0.01em] sm:text-base text-[#747984]'>CRM Solutions</h1>
                                </article>
                                <article className='mb-4 flex items-center'>
                                    <img src={ scale1 } className='w-6 h-6 inline-flex align-middle mr-2' alt="" />
                                    <h1 className='inline-flex align-top mt-1 xs:font-[400] xs:text-sm xs:leading-[1.125rem] xs:tracking-[-0.01em] sm:text-base text-[#747984]'>Customized Industry Solutions</h1>
                                </article>
                            </div>
                        </div>
                    </div>

                </div>

                <div className="!min-w-[15.625rem] max-w-[34.375rem] lg:pt-38 lg:ml-16 md:pt-[-10rem] sm:pt-[-22.5rem] xs:pt-[0.125rem] mr-0">
                    <img src={ offerings } alt="" />
                </div>
                <div className="font-DM-Sans xs:block sm:hidden md:hidden lg:hidden min-w-[20rem] ">
                    <h1 className='lg:font-medium lg:text-base lg:leading-[1.125rem] lg:tracking-[0.12em] sm:text-sm sm:font-medium sm:leading-[0.938rem] sm:tracking-[0.12em] xs:font-medium xs:text-sm xs:leading- xs:tracking-[0.12em] text-[#ff8400] pb-4 '>Offerings</h1>
                    <p className='lg:font-bold lg:text-4xl lg:leading-[2.688rem] sm:font-bold sm:text-3xl sm:leading-9 xs:font-bold xs:text-2xl xs:leading-8 text-[#293451] pb-5'>
                        {/* Solutions we provide - IT support, consulting services for businesses */ }
                        Solutions we provide - IT infrastructure development and support, consulting services for businesses
                    </p>
                </div>
            </div>


        </>
    )
}

export default Offerings
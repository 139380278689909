/** @format */

import { useEffect, useRef, useState } from "react";

import logo from "../assets/Logo.svg";
import menu from "../assets/menu1.svg";
import close from "../assets/menu1.svg";
import { navLinks } from "../constants";

const Navbar = () => {
  const [active, setActive] = useState("Home");
  const [toggle, setToggle] = useState(false);
  const toggleRef = useRef(null);

  // Handling close of hamburger menu on outside click of it

  const handleClickOutside = (event) => {
    if (toggleRef.current && !toggleRef.current.contains(event.target)) {
      setToggle(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);

    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, []);

  return (
    <nav className='max-w-[120rem] w-full flex py-6 xs:px-6 sm:px-[3.125rem] justify-between items-center navbar m-auto'>
      <img
        src={logo}
        alt='lcs-logo'
        className='lg:w-[4.375rem] lg:h-[2.5rem] w-[3.375rem] h-[1.875rem]'
      />

      <ul className='list-none sm:flex hidden justify-end items-center flex-1 '>
        {navLinks.map((nav, index) => (
          <li
            key={nav.id}
            className={`font-DM-Sans font-normal cursor-pointer lg:text-base sm:text-xs ${
              active === nav.title ? "text-white" : "text-dimWhite"
            } ${index === navLinks.length - 1 ? "mr-0" : "pr-8"}`}
            onClick={() => setActive(nav.title)}
          >
            <a href={`#${nav.id}`} className={"" + nav.class ?? ""}>
              {nav.title}
            </a>
          </li>
        ))}
      </ul>

      <div className='sm:hidden flex flex-1 justify-end items-center'>
        <img
          src={toggle ? close : menu}
          alt='menu'
          className='w-[1.75rem] h-[1.75rem] object-contain'
          onClick={() => setToggle(!toggle)}
          ref={toggleRef}
        />

        <div
          className={`${
            !toggle ? "hidden" : "flex"
          } p-6 bg-black-gradient absolute top-20 right-0 mx-4 my-2 min-w-[8.75rem] rounded-xl sidebar z-10 bg-[#fff] `}
          id='respNav'
        >
          <ul className='list-none flex justify-end items-start flex-1 flex-col'>
            {navLinks.map((nav, index) => (
              <li
                key={nav.id}
                className={`font-DM-Sans font-medium cursor-pointer text-base ${
                  active === nav.title ? "text-white" : "text-dimWhite"
                } ${index === navLinks.length - 1 ? "mb-0" : "mb-4"}`}
                onClick={() => setActive(nav.title)}
              >
                <a href={`#${nav.id}`}>{nav.title}</a>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;

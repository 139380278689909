import React from "react";
import Clock from "./../assets/IconClock.png";
import Submit from "../assets/Submit.png";
import Img from "../assets/LCS-Home.png";

function Home ()
{


  const handleButtonClick = ( event ) =>
  {
    const contactUsSection = document.getElementById( 'contactus' );

    if ( contactUsSection )
    {
      contactUsSection.scrollIntoView( { behavior: 'smooth' } );
    }

    event.preventDefault();
  };



  return (
    <section
      id="home"
      className="flex flex-col-reverse sm:flex-row w-full max-w-[120rem] py-20 xs:px-5 sm:px-14 m-auto gap-4"
    >
      <div className="flex-1">
        <div className="inline-flex">
          <svg
            width="17"
            height="19"
            viewBox="0 0 17 19"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M16.5236 8.5076H10.1842L14.6666 3.5374L13.5949 2.34905L9.11249 7.31926V0.290039H7.59663V7.31926L3.1142 2.34905L2.04249 3.5374L6.52491 8.5076H0.185547V10.1884H6.52491L2.04249 15.1586L3.1142 16.347L7.59663 11.3768V18.406H9.11249V11.3768L13.5949 16.347L14.6666 15.1586L10.1842 10.1884H16.5236V8.5076Z"
              fill="#010AE9"
            />
          </svg>
          <p className="font-Miriam-Libre pl-2 text-p-blue-0">
            Jump start your growth
          </p>
        </div>
        <div className="">
          <p className="font-DM-Sans font-bold sm:text-[2.75rem] leading-[3.375rem] tracking-[-0.03em] lg:text-6xl pt-4 xs:text-4xl text-[#0d0000]">
            Revolutionize your business with custom{ " " }
            <span className="text-p-blue-0">low code</span> solutions
            <img
              className="inline -mt-4 sm:w-[4.125rem] xs:w-[4.5rem] lg:w-[5.625rem]"
              src={ Clock }
              alt=""
            ></img>
          </p>
          <p className="font-DM-Sans pt-6">
            {/* Switch to the Low-code system to create <br />
            business applications that will operate <br />
            automatically on any device.. */}
            To quickly create business apps that can operate simultaneously on every device, switch to the low-code systems.

          </p>
          <div className="flex flex-row">
            <form className="pt-10 xs:basis-full sm:basis-4/5 flex flex-row">
              {/* <input
                type="email"
                name=""
                id=""
                placeholder=" Email address"
                className="input-group-lg rounded-xl h-14 border placeholder-[#dedddd] font-DM-Sans text-sm basis-2/3 pl-4 mr-4 -skew-x-12"
              /> */}
              <a href="#contactus" onClick={ handleButtonClick }>
                <button
                  className="btn bg-p-blue-0 text-white rounded-xl h-14 border-none basis-1/3 -skew-x-12"
                  type="submit"


                >
                  {/* <img src={ Submit } alt="" /> */ }
                  <span className="!text-[#ffffff] !font-DM-Sans !lg:text-base !sm:text-xs !font-normal">Contact Us</span>

                </button>
              </a>
            </form>
          </div>
        </div>
      </div>
      <div className="flex-1 flex sm:justify-end xs:justify-center xs:pb-10 sm:pb-0 relative">
        <div className="bg-[url('../src/components/assets/LCS-Home-bg.png')] object-cover bg-no-repeat w-full lg:w-1/2 h-full xs:blur-[3.322rem] sm:blur-[3.67rem] lg:blur-[4.895rem] opacity-30 absolute">
        </div>
        <img
          src={ Img }
          alt=""
          className="sm:w-[22.5rem] w-[31.25rem] lg:w-[31.25rem] h-auto "
        />
      </div>
    </section>
  );
}

export default Home;

/** @format */

import Marqone from "./marqone";
import Marqtwo from "./marqtwo";
import Platform1 from "../assets/Platform1.svg";
import Platform2 from "../assets/Platform2.svg";
import Platform3 from "../assets/Platform3.svg";
import outsystems from "../assets/Platforms/outsystems.png";
import mendix from "../assets/Platforms/mendix.png";
import ReadMore from "../assets/ReadMore.svg";
import MicrosoftPowerApps from "../assets/MicrosoftPowerApps.svg";

function Platforms() {
  return (
    <>
      {/* Nabeel's Code  */}

      {/* <section className=" m-auto" id='platform'>
        <div className="text-center bg-gradient-to-t from-[#F4FDFE] to-[#F2F6FF] h-[48vw]">
            <div className="flex">
                <img className="absolute left-0 mt-2 " src={Platform1} alt="" />
                <img className="absolute right-0" src={Platform2} alt="" />
            </div>
            <h1 className="font-DM-Sans font-medium text-4xl p-20">Low code platforms we use</h1>
            <Marqone />
            <Marqtwo />
            <div className="flex p-28">
                <div className="card-body font-DM-Sans w-[28%] border border-[#82C3EC] border-opacity-20 bg-[#fff] rounded-lg m-8">
                    <div className="m-4">
                        <img className='' src={outsystems} alt="" />
                    </div>
                    <p className="text-base text-[#838381] self-center text-center">Lorem ipsum dolor sit amet, coectetur adipiscing elit. Nunc vulputate libero et velit interdum, ac aliquet odio mattis. Class aptent taciti sociosqu ad litora , ac aliquet odio mattis. Class aptent citi</p>
                    <section className="m-4 text-justify">
                        <a href="/">
                            <img className="inline" src={ReadMore} alt="" />
                            <h1 className="inline ml-2 text-[#017EFA]">Read More</h1>
                        </a>
                    </section>
                </div>
                <div className="card-body font-DM-Sans w-[28%] border border-[#82C3EC] border-opacity-20 bg-[#fff] rounded-lg m-8">
                    <div className="m-4">
                        <img className='' src={mendix} alt="" />
                    </div>
                    <p className="text-base text-[#838381] self-center text-center">Lorem ipsum dolor sit amet, coectetur adipiscing elit. Nunc vulputate libero et velit interdum, ac aliquet odio mattis. Class aptent taciti sociosqu ad litora , ac aliquet odio mattis. Class aptent citi</p>
                    <section className="m-4 text-justify">
                        <a href="/">
                            <img className="inline" src={ReadMore} alt="" />
                            <h1 className="inline ml-2 text-[#017EFA]">Read More</h1>
                        </a>
                    </section>
                </div>
                <div className="card-body font-DM-Sans w-[28%] border border-[#82C3EC] border-opacity-20 bg-[#fff] rounded-lg m-8">
                    <div className="m-4">
                        <img className='' src={appian} alt="" />
                    </div>
                    <p className="text-base text-[#838381] self-center text-center">Lorem ipsum dolor sit amet, coectetur adipiscing elit. Nunc vulputate libero et velit interdum, ac aliquet odio mattis. Class aptent taciti sociosqu ad litora , ac aliquet odio mattis. Class aptent citi</p>
                    <section className="m-4 text-justify">
                        <a href="/">
                            <img className="inline" src={ReadMore} alt="" />
                            <h1 className="inline ml-2 text-[#017EFA]">Read More</h1>
                        </a>
                    </section>
                </div>
                <img className="absolute right-0 mt-44" src={Platform3} alt="" />
            </div>
        </div>
            </section> */}

      <section className=' m-auto w-full max-w-[120rem]' id='platform'>
        <div className='text-center bg-gradient-to-t from-[#F4FDFE] to-[#F2F6FF] h-[45rem] w-full sticky'>
          <div className='flex max-w-[120rem] relative'>
            <img className='absolute left-0 mt-2 ' src={Platform1} alt='' />
            <img className='absolute right-0' src={Platform2} alt='' />
          </div>
          <h1 className='font-DM-Sans font-medium text-2xl leading-7 sm:text-4xl p-20 relative z-10'>
            Low code platforms we use
          </h1>
          <Marqone />
          <Marqtwo />
          <div className='flex py-28 relative '>
            <div className='flex xs:flex-col sm:flex-row max-w-[120rem] m-auto'>
              <div className='xs:mx-10 z-[10] card-body font-DM-Sans border border-[#82C3EC] border-opacity-20 bg-[#fff] rounded-lg xs:max-w-[21.75rem] xs:h-auto xs:rounded-lg xs:px-[1.476rem] xs:py-[0.125rem] xs:font-[400] xs:text-sm xs:leading-[1.188rem] w-auto sm:!mx-8 xs:!my-8 sm:!my-0 lg:!my-0 md:!my-0'>
                <div className='py-8 '>
                  <div className='xs:w-[12.196rem] xs:h-[4.071rem] sm:w-[5.625rem] sm:h-[1.983rem] lg:w-[7.596rem] lg:h-[2.486rem] '>
                    <img
                      className='object-contain w-[90px] h-8'
                      src={outsystems}
                      alt=''
                    />
                  </div>
                  <p className='xs:text-sm text-base text-[#838381] flex justify-center text-left'>
                    {/* Lorem ipsum dolor sit amet, coectetur adipiscing elit. Nunc vulputate libero et velit interdum, ac aliquet odio mattis. Class aptent taciti sociosqu ad litora , ac aliquet odio mattis. Class aptent citi */}
                    Outsystems excels in native mobile app development,
                    providing true native apps with offline functionality. Its
                    visual development environment accelerates app creation,
                    benefiting businesses from rapid, high-performance mobile
                    solutions. Beyond app development, Outsystems excels in
                    business process automation (BPA), streamlining operations.
                    It also specializes in integrating with legacy systems,
                    allowing organizations to modernize gradually, reducing
                    costs and risks associated with system replacements. This
                    versatility supports comprehensive digital transformation
                    efforts.
                  </p>
                  {/* <section className=" text-justify xs:pt-[1.469rem]   ">
                                        <a href="https://www.outsystems.com/">
                                            <img className="inline" src={ ReadMore } alt="" />
                                            <h1 className="inline ml-2 text-[#017EFA] font-Gilroy-Medium ">Read More</h1>
                                        </a>
                                    </section> */}
                </div>
              </div>

              <div className='xs:mx-10 z-[10] card-body font-DM-Sans border border-[#82C3EC] border-opacity-20 bg-[#fff] rounded-lg xs:max-w-[21.75rem] xs:h-auto xs:rounded-lg xs:px-[1.476rem] xs:py-[0.125rem] xs:font-[400] xs:text-sm xs:leading-[1.188rem] w-auto sm:!mx-8 xs:!my-8 sm:!my-0 lg:!my-0 md:!my-0'>
                <div className='py-8'>
                  <div className='xs:w-[12.196rem] xs:h-[4.071rem] sm:w-[5.625rem] sm:h-[1.983rem] lg:w-[7.596rem] lg:h-[2.486rem]'>
                    <img className='object-contain' src={mendix} alt='' />
                  </div>
                  <p className='xs:text-sm text-base text-[#838381] flex justify-center text-left'>
                    {/* Lorem ipsum dolor sit amet, coectetur adipiscing elit. Nunc vulputate libero et velit interdum, ac aliquet odio mattis. Class aptent taciti sociosqu ad litora , ac aliquet odio mattis. Class aptent citi */}
                    Mendix's unique capabilities include effortless multi-cloud
                    deployment, ideal for diverse cloud strategies. Its
                    collaborative development environment encourages teamwork
                    among different stakeholders. Mendix's app store-like
                    marketplace provides a rich selection of pre-built
                    components, streamlining development and extending
                    functionality. It also offers advanced AI and machine
                    learning services, enabling the incorporation of
                    sophisticated AI features. Furthermore, Mendix supports
                    micro services architecture, facilitating the creation of
                    scalable and modular applications.
                  </p>
                  {/* <section className=" text-justify xs:pt-[1.469rem] ">
                                        <a href="https://www.mendix.com/">
                                            <img className="inline" src={ ReadMore } alt="" />
                                            <h1 className="inline ml-2 text-[#017EFA] font-Gilroy-Medium">Read More</h1>
                                        </a>
                                    </section> */}
                </div>
              </div>

              <div className='xs:mx-10 z-[10] card-body font-DM-Sans border border-[#82C3EC] border-opacity-20 bg-[#fff] rounded-lg xs:max-w-[21.75rem] xs:h-auto xs:rounded-lg xs:px-[1.476rem] xs:py-[0.125rem] xs:font-[400] xs:text-sm xs:leading-[1.188rem] w-auto sm:!mx-8 xs:!my-8 sm:!my-0 lg:!my-0 md:!my-0'>
                <div className='py-8'>
                  <div className='xs:w-[12.196rem] xs:h-[4.071rem] sm:w-[5.625rem] sm:h-[1.983rem] lg:w-[7.596rem] lg:h-[2.486rem]'>
                    <img
                      className='object-contain'
                      src={MicrosoftPowerApps}
                      alt=''
                    />
                  </div>
                  <p className='xs:text-sm text-base text-[#838381] flex justify-center text-left'>
                    {/* Lorem ipsum dolor sit amet, coectetur adipiscing elit. Nunc vulputate libero et velit interdum, ac aliquet odio mattis. Class aptent taciti sociosqu ad litora , ac aliquet odio mattis. Class aptent citi */}
                    Microsoft Power Apps excels in rapid app development,
                    empowering businesses to create custom apps with minimal
                    coding. Its integration with Microsoft 365, Dynamics 365,
                    and Azure enhances productivity by seamlessly connecting
                    with existing Microsoft tools. Power Apps supports AI
                    Builder for incorporating AI features and offers
                    industry-specific templates for sectors like healthcare,
                    finance, and retail. Its robust security framework aligns
                    with enterprise-grade compliance standards, making it ideal
                    for diverse business needs.
                  </p>
                  {/* <section className="text-justify xs:pt-[1.469rem]">
                                        <a href="https://appian.com/">
                                            <img className="inline" src={ ReadMore } alt="" />
                                            <h1 className="inline ml-2 text-[#017EFA] font-Gilroy-Medium">Read More</h1>
                                        </a>
                                    </section> */}
                </div>
              </div>
            </div>
            <img
              className='absolute right-0 xs:mt-[8.125rem] sm:!mt-25 md:mt-40 '
              src={Platform3}
              alt=''
            />
          </div>
        </div>
      </section>
    </>
  );
}

export default Platforms;

// import logo from './logo.svg';
// import logo from './assets/APSIS LOGO.png';
// import './App.css';
import Navbar from "./components/layouts/Navbar";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
// import Home from "./components/layouts/Home";
import Home from "./components/layouts/Home";
import WhyLowCode from "./components/layouts/WhyLowCode";
import Offerings from "./components/layouts/Offerings";
import Platforms from "./components/layouts/Platforms";
import Platform2 from "./components/layouts/Platform2";
import AboutUs from "./components/layouts/AboutUs";
import ContactUs from "./components/layouts/ContactUs";
import Footer from "./components/layouts/Footer";
import ScaleFlex from "./components/layouts/ScaleFlex";
import GetInTouch from "./components/layouts/GetInTouch";
import GetInTouchSecond from "./components/layouts/GetInTouchSecond"
import ContactUs2 from "./components/layouts/ContactUs";
// import Nav from "./components/layouts/Nav";

function App ()
{
  return (
    <>
      <section className="sm:bg-[url('../src/components/assets/VectorT.svg')] lg:bg-[url('../src/components/assets/Vector.svg')] xs:bg-[url('../src/components/assets/VectorM.svg')] object-cover bg-no-repeat max-w-[120rem] m-auto">
        <Router>
          <Routes>
            <Route path="/" element=<Navbar /> />
          </Routes>
          <Home />
          <WhyLowCode />
          <ScaleFlex />
          <Offerings />
          <Platforms />
          <Platform2 />
          {/* Removed AboutUs As Per Requirement */ }
          {/* <AboutUs /> */ }
          {/* <GetInTouch /> */ }
          {/* <GetInTouchSecond /> */ }
          <ContactUs />
          {/* <ContactUs2 /> */ }
          <Footer />

        </Router>
      </section>

    </>
  );
}

export default App;

/** @format */

import React from "react";
import Mobile from "../assets/Mobile.svg";
import Mail from "../assets/Mail.svg";
import Facebook from "../assets/Facebook.svg";
import Twitter from "../assets/Twitter.svg";
import LinkedIn from "../assets/Linkedin.svg";
import Instagram from "../assets/Instagram.svg";
import { Phone } from "@mui/icons-material";

function Footer() {
  return (
    <section id='footer' className='w-full max-w-[120rem] m-auto bg-[#F2F6FF]'>
      <article className='xs:grid-row-4 xs:grid-cols-1 m-auto grid sm:grid-cols-12 gap-4 xs:px-16 sm:px-5 lg:px-24 pt-24 pb-14 xs:mx-auto'>
        <div className='sm:col-span-4 text-left '>
          <h1 className='xs:text-base xs:font-[400] xs:leading-4 xs:items-stretch sm:items-center font-DM-Sans sm:text-lg leading-4 xs:pb-4 sm:pb-8 text-[#242424]'>
            Low Code Systems Software Private Limited
          </h1>
          <p className='xs:text-sm  xs:leading-6 xs:tracking-[-0.02em] xs:items-stretch sm:text-base font-DM-Sans max-w-[18rem] text-[#767676] xs:pb-4 sm:pb-12  '>
            {/* Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vulputate libero et velit interdu */}
            Majestique Biznow, 3rd floor, 301 A, NIBM Road, Pune-411048
          </p>
          <a href='https://www.facebook.com/profile.php?id=61553103520730'>
            <img src={Facebook} alt='' className='inline pr-4' />
          </a>
          <a href='https://www.instagram.com/lowcosys/?utm_source=qr&igshid=MThlNWY1MzQwNA%3D%3D'>
            <img src={Instagram} alt='' className='inline pr-4' />
          </a>

          <a href='https://www.linkedin.com/company/low-code-systems-software-private-limited/'>
            <img src={LinkedIn} alt='' className='inline pr-4' />
          </a>

          {/* <img src={ Twitter } alt="" className="inline pr-4" /> */}
        </div>
        <div className='col-span-4 text-left xs:pt-8 sm:pt-0'>
          <h1 className='xs:text-base xs:font-[400] xs:leading-4 xs:items-stretch sm:items-center font-DM-Sans sm:text-lg leading-4 xs:pb-4 sm:pb-8 text-[#242424]'>
            Contact
          </h1>
          {/* <h2 className='xs:text-sm xs:leading-4 xs:items-stretch font-DM-Sans pb-2 max-w-[14rem] text-[#666666] xs:pb-4 sm:pb-3 '>
                        <img src={ Mobile } alt="" className="inline pr-2 " />
                        +91 9999999999
                    </h2> */}
          <h2 className='text-[#666666] pb-4 xs:text-sm xs:leading-4 xs:items-stretch font-DM-Sans max-w-[14rem] xs:pb-4 sm:pb-12 '>
            <img src={Mail} alt='' className='inline pr-2' />
            <a href='mailto:info@lowcosys.com'>info@lowcosys.com</a>
          </h2>
          <h2 className='text-[#666666] pb-4 xs:text-sm xs:leading-4 xs:items-stretch font-DM-Sans max-w-[14rem] xs:pb-4 sm:pb-12 '>
            <Phone sx={{ color: "black" }} />
            <a href='mailto:info@lowcosys.com'>+91 92094 64600</a>
          </h2>
        </div>
        <div className='xs:flex sm:hidden xs:pt-8 sm:pt-0 '>
          <div className='col-span-2'>
            <h1 className='xs:text-base xs:font-[400] xs:leading-4 xs:items-stretch sm:items-center font-DM-Sans sm:text-lg leading-4 xs:pb-4 sm:pb-8 text-[#242424]'>
              About
            </h1>
            <h2 className='font-DM-Sans text-[#666666] text-sm pb-4'>
              {" "}
              <a href='#whylowcode'>Why Low Code</a>
            </h2>
            <h2 className='font-DM-Sans text-[#666666] text-sm pb-4'>
              {" "}
              <a href='#scale'>Our Services</a>
            </h2>
            <h2 className='font-DM-Sans text-[#666666] text-sm pb-4'>
              <a href='#platform'>Platforms</a>
            </h2>
            {/* <h2 className='font-DM-Sans text-[#666666] text-sm pb-4'><a href="#scale">About Us</a></h2> */}
            <h2 className='font-DM-Sans text-[#666666] text-sm pb-4'>
              <a href='#contactus'>Contact Us</a>
            </h2>
          </div>
          <div className='col-span-2 pl-5'>
            <h1 className='xs:text-base xs:font-[400] xs:leading-4 xs:items-stretch sm:items-center font-DM-Sans sm:text-lg leading-4 xs:pb-4 sm:pb-8 text-[#242424]'>
              Support
            </h1>
            <h2 className='font-DM-Sans text-[#666666] text-sm pb-4'>FAQ</h2>
            <h2 className='font-DM-Sans text-[#666666] text-sm pb-4'>Policy</h2>
            <h2 className='font-DM-Sans text-[#666666] text-sm pb-4'>
              Business
            </h2>
          </div>
        </div>
        <div className='col-span-2 xs:hidden sm:grid xs:pt-8 sm:pt-0'>
          <h1 className='font-DM-Sans text-lg leading-4 pb-8 text-[#242424]'>
            {" "}
            About
          </h1>
          <h2 className='font-DM-Sans text-[#666666] text-sm pb-4'>
            {" "}
            <a href='#whylowcode'>Why Low Code</a>
          </h2>
          <h2 className='font-DM-Sans text-[#666666] text-sm pb-4'>
            <a href='#scale'>Our Services</a>
          </h2>
          <h2 className='font-DM-Sans text-[#666666] text-sm pb-4'>
            <a href='#platform'>Platforms</a>
          </h2>
          {/* <h2 className='font-DM-Sans text-[#666666] text-sm pb-4'>About Us</h2> */}
          <h2 className='font-DM-Sans text-[#666666] text-sm pb-4'>
            <a href='#contactus'>Contact Us</a>
          </h2>
        </div>
        <div className='col-span-2 xs:hidden sm:grid xs:pt-8 sm:pt-0 '>
          <h1 className='font-DM-Sans text-lg leading-4 text-[#242424]'>
            Support
          </h1>
          <h2 className='font-DM-Sans text-[#666666] text-sm '>FAQ</h2>
          <h2 className='font-DM-Sans text-[#666666] text-sm '>Policy</h2>
          <h2 className='font-DM-Sans text-[#666666] text-sm '>Business</h2>
        </div>
      </article>
      <div className='xs:px-12 sm:px-44 sm:pb-24'>
        <div className='font-DM-Sans text-left text-[#666666] text-xs pb-4 border-t border-[#E6E6E6]'>
          <span className='pt-4'>Copyright © 2021 lowcosys.com</span>
        </div>
      </div>
    </section>
  );
}

export default Footer;

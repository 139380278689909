import React from 'react'
import One from '../assets/Platform2/1.svg'
import Two from '../assets/Platform2/2.svg'
import Three from '../assets/Platform2/3.svg'
import Four from '../assets/Platform2/4.svg'
import Five from '../assets/Platform2/5.svg'
import Six from '../assets/Platform2/6.svg'
import Ideate from '../assets/Platform2/Ideate.svg'
import Vector1 from '../assets/Platform2/Vector1.svg'
import Planning from '../assets/Platform2/Planning.svg'
import Vector2 from '../assets/Platform2/Vector2.svg'
import Development from '../assets/Platform2/Development.svg'
import Testing from '../assets/Platform2/Testing.svg'
import Launching from '../assets/Platform2/Launching.svg'
import Support from '../assets/Platform2/Support.svg'

function Platform2 ()
{
  return (
    <section
      id="platform2"
      className="flex w-full sm:max-w-[120rem] pt-20 px-14 m-auto xs:mt-[90rem] md:mt-[50rem] lg:mt-[20rem] "
    >
      <div className='pt-20 text-center justify-center m-auto'>
        <article className='font-DM-Sans'>
          <h1 className='text-5xl font-bold text-center xs:text-[1.75rem] xs:font-[700] xs:leading-[2.063rem] sm:font-[700] sm:text-4xl sm:leading-[2.625rem]  ' >We Complete every step carefully</h1>
          <p className='pt-4 xs:font-[400] xs:text-sm leading-4  '>Why should a company or organization choose a low code platform to create custom applications?</p>
        </article>
        <article className='xs:!max-w-[15.625rem] sm:m-20 mb-0 sm:!max-w-[66.25rem] py-10 xs:m-auto'>
          <div className="grid sm:grid-cols-5 sm:grid-rows-1 xs:grid-rows-1 sm:gap-4 pb-20 justify-items-center">
            <div className='relative'>
              <img src={ One } className='absolute -m-2' alt="" />
              <img src={ Ideate } className='m-auto' alt="" />
              <h1 className='xs:font-bold xs:text-lg xs:leading-[115%] xs:tracking-[-0.05em] mt-12 sm:font-bold sm:text-xl text-[#424242] font-DM-Sans '>Ideate</h1>
              <p className='xs:text-xs xs:leading-[131.02%] sm:text-sm text-[#6A6B6B] font-Gilroy-Regular text-center mt-4 '>Define the requirements and objectives of the application you want to create.</p>
            </div>
            <div className=''>
              <img src={ Vector1 } className='mt-20 xs:hidden sm:block' alt="" />
            </div>
            <div className='relative xs:py-14 sm:py-0'>
              <img src={ Two } className='absolute -m-2' alt="" />
              <img src={ Planning } className='m-auto' alt="" />
              <h1 className='xs:font-bold xs:text-lg xs:leading-[115%] xs:tracking-[-0.05em] mt-12 sm:font-bold sm:text-xl text-[#424242] font-DM-Sans '>Planning</h1>
              <p className='xs:text-xs xs:leading-[131.02%] sm:text-sm text-[#6A6B6B] font-Gilroy-Regular text-center mt-4 '>Determine the structure and layout of your website</p>
            </div>
            <div className=''>
              <img src={ Vector2 } className='mt-10 xs:hidden sm:block' alt="" />
            </div>
            <div className='relative'>
              <img src={ Three } className='absolute -m-2' alt="" />
              <img src={ Development } className='m-auto' alt="" />
              <h1 className='xs:font-bold xs:text-lg xs:leading-[115%] xs:tracking-[-0.05em] mt-12 sm:font-bold sm:text-xl text-[#424242] font-DM-Sans '>Development</h1>
              <p className='xs:text-xs xs:leading-[131.02%] sm:text-sm text-[#6A6B6B] font-Gilroy-Regular text-center mt-4 '>Configure the application's behavior and functionality using the low-code platform's</p>
            </div>
          </div>
          <div className="grid sm:grid-cols-5 xs:grid-rows-1 sm-mt-0 sm:grid-rows-1 sm:gap-4 sm:pt-20 justify-items-center ">
            <div className='relative'>
              <img src={ Four } className='absolute -m-2' alt="" />
              <img src={ Testing } className='m-auto' alt="" />
              <h1 className='xs:font-bold xs:text-lg xs:leading-[115%] xs:tracking-[-0.05em] mt-12 sm:font-bold sm:text-xl text-[#424242] font-DM-Sans '>Testing</h1>
              <p className='xs:text-xs xs:leading-[131.02%] sm:text-sm text-[#6A6B6B] font-Gilroy-Regular text-center mt-4 '>Test the application to ensure it meets your requirements and works as expected.</p>
            </div>
            <div className=''>
              <img src={ Vector1 } className='mt-20 xs:hidden sm:block' alt="" />
            </div>
            <div className='relative xs:py-14 sm:py-0'>
              <img src={ Five } className='absolute -m-2' alt="" />
              <img src={ Launching } className='m-auto' alt="" />
              <h1 className='xs:font-bold xs:text-lg xs:leading-[115%] xs:tracking-[-0.05em] mt-12 sm:font-bold sm:text-xl text-[#424242] font-DM-Sans '>Launching</h1>
              <p className='xs:text-xs xs:leading-[131.02%] sm:text-sm text-[#6A6B6B] font-Gilroy-Regular text-center mt-4 '>Deploy the application and make it available to users.</p>
            </div>
            <div className=''>
              <img src={ Vector2 } className='mt-10 xs:hidden sm:block' alt="" />
            </div>
            <div className='relative'>
              <img src={ Six } className='absolute -m-2' alt="" />
              <img src={ Support } className='m-auto' alt="" />
              <h1 className='xs:font-bold xs:text-lg xs:leading-[115%] xs:tracking-[-0.05em] mt-12 sm:font-bold sm:text-xl text-[#424242] font-DM-Sans'>Support</h1>
              <p className='xs:text-xs xs:leading-[131.02%] sm:text-sm text-[#6A6B6B] font-Gilroy-Regular text-center mt-4 '>Monitor and manage the application's performance and usage, and make any necessary updates over time</p>
            </div>
          </div>
        </article>
      </div>
    </section>
    // <section className='pt-40 m-auto'>
    //     <div className='pr-40 pl-40 pt-20'>
    //         <article className='font-DM-Sans text-center '>
    //             <h1 className='text-5xl font-bold' >We Complete every step carefully</h1>
    //             <p className='pt-4 '>Why should a company or organization choose a low code platform to create custom applications?</p>
    //         </article>
    //         <article className='pt-20'>
    //             <h1>Hello</h1>
    //         </article>
    //     </div>
    // </section>
  )
}

export default Platform2
import React from 'react'
import scale from '../assets/Scale.png'
import scale1 from '../assets/Scale1.png'
import scale2 from '../assets/Scale2.png'
import scale3 from '../assets/Scale3.png'

function ScaleFlex ()
{
    return (
        <>
            <div className="xs:px-8 flex items-center py-10 sm:mb-14 sm:px-8 sm:justify-evenly mt-3 max-w-[120rem] m-auto " id="scale">
                <div className="xs:hidden sm:flex justify-center w-auto !min-w-[15rem] max-w-[38.75rem] !mx-0 !px-0 ">
                    <img src={ scale } alt="" className='' />
                </div>
                <div className='flex-col'>
                    <div className=' font-DM-Sans ' >
                        <h1 className='lg:font-medium lg:text-base lg:leading-[1.125rem] lg:tracking-[0.12em] sm:text-sm sm:font-medium sm:leading-[0.938rem] sm:tracking-[0.12em] xs:font-medium xs:text-sm xs:leading-[0.938rem] xs:tracking-[0.12em] text-[#ff8400] pb-4 '>Scale</h1>
                        <p className='xs:min-w-[15rem] xs:max-w-[20rem] sm:min-w-[21.875rem] max-w-[28.125rem] lg:font-bold lg:text-4xl lg:mb-8 lg:leading-[2.688rem] sm:text-[1.875rem] sm:font-bold sm:text-3xl sm:leading-9 xs:font-bold xs:text-2xl xs:leading-9 text-[#293451]'>How we help you to scale up your business with<span className='text-[#6a7eda]'> Low code</span></p>
                    </div>

                    <div className="sm:hidden xs:flex ">
                        <img src={ scale } alt="" className='!min-w-[15rem] sm:max-w-[34.375rem]' />
                    </div>

                    <div className="xs:flex-col sm:block mt-6 ">
                        <article className='pb-4 flex sm:min-w-[21.875rem] sm:max-w-[28.125rem] items-center'>
                            <img src={ scale1 } className='w-6 h-6 rounded-full inline-flex align-bottom mr-2' alt="" />
                            <h1 className='inline-flex lg:font-[400] lg:text-base lg:leading-6 lg:-tracking-[0.01em] sm:font-[400] sm:text-sm sm:leading-5 sm:-tracking-[-0.01em]  xs:font-[400] xs:text-sm xs:leading-5 xs:-tracking-[-0.01em] '>We don't just start your project - we assess your company's needs to find the best solution.
                            </h1>
                        </article>
                        <article className='pb-4 flex sm:min-w-[21.875rem] sm:max-w-[28.125rem] items-center'>
                            <img src={ scale2 } className='w-6 h-6 inline-flex align-bottom mr-2' alt="" />
                            <h1 className='inline-flex lg:font-[400] lg:text-base lg:leading-6 lg:-tracking-[0.01em] sm:font-[400] sm:text-sm sm:leading-5 sm:-tracking-[-0.01em]  xs:font-[400] xs:text-sm xs:leading-5 xs:-tracking-[-0.01em]'>Scaling your business at the right time is crucial for growth, but it can be difficult and costly.
                            </h1>
                        </article>
                        <article className='pb-4 flex sm:min-w-[21.875rem] sm:max-w-[28.125rem] items-center'>
                            <img src={ scale3 } className='w-6 h-6 inline-flex align-bottom mr-2' alt="" />
                            <h1 className='inline-flex lg:font-[400] lg:text-base lg:leading-6 lg:-tracking-[0.01em] sm:font-[400] sm:text-sm sm:leading-5 sm:-tracking-[-0.01em]  xs:font-[400] xs:text-sm xs:leading-5 xs:-tracking-[-0.01em]'>Low code technology makes it easier and more cost-effective to scale your business by expanding your IT infrastructure
                            </h1>
                        </article>
                    </div>
                </div>

            </div>
        </>
    )
}

export default ScaleFlex
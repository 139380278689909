/** @format */

import React from "react";
import ImprovedAgility from "../assets/ImprovedAgility.png";
import LowerExpenditure from "../assets/LowerExpenditure.svg";
import FasterTransformation from "../assets/FasterTransformation.png";
import HighProductivity from "../assets/HighProductivity.png";
import IncreasedProfitability from "../assets/IncreasedProfitability.png";
import ImprovesUserExperience from "../assets/ImprovesUserExperience.png";
import { FaDollarSign } from "react-icons/fa6";

function WhyLowCode() {
  return (
    <section
      id='whylowcode'
      className='flex flex-col w-full max-w-[120rem] pt-20 m-auto justify-center sm:mb-8'
    >
      <main className='text-center font-DM-Sans'>
        <h1 className=' font-bold xs:text-3xl sm:text-4xl lg:text-5xl pb-6'>
          Why Low Code?
        </h1>
        {/* <p className='xs:text-sm xs:px-5 sm:px-0 lg:text-base text-[#6e6e6c] max-w-xl m-auto'>
                    Why should a company or organization choose a low code platform to create custom applications?
                </p> */}
      </main>

      <div className='pt-16 sm:px-16'>
        <div className='grid xs:grid-rows-6 sm:grid-rows-2 sm:grid-cols-3 gap-4'>
          <div className='card-body font-DM-Sans xs:w-8/12 sm:w-11/12 border border-[#F5F5F5] rounded-lg m-4 justify-self-center hover:shadow-xl shadow-card1 sm:shadow-none'>
            <div className='rounded-full xs:w-16 xs:h-16 lg:w-[5.313rem] lg:h-[5.313rem] self-center m-8'>
              <img className='' src={ImprovedAgility} alt='' />
            </div>
            <h1 className='xs:text-base lg:text-xl text-[#424242] self-center text-center'>
              Improved agility
            </h1>
            <p className='xs:text-xs lg:text-base text-[#838381] self-center text-center max-w-[17.375rem] m-auto'>
              {/* Refers to the ability to quickly and easily adapt to changing business needs and requirements */}
              Helps organisations to quickly and easily adapt to changing
              business needs and requirements.
            </p>
          </div>

          <div className='card-body font-DM-Sans xs:w-8/12 sm:w-11/12 border border-[#F5F5F5] rounded-lg m-4 justify-self-center hover:shadow-xl shadow-card1 sm:shadow-none'>
            <div className='rounded-full xs:w-16 xs:h-16 lg:w-[5.313rem] lg:h-[5.313rem] self-center m-8'>
              <img className='' src={LowerExpenditure} alt='' />
            </div>
            <h1 className='xs:text-base lg:text-xl text-[#424242] self-center text-center'>
              Lower Expenditure
            </h1>
            <p className='xs:text-xs lg:text-base text-[#838381] self-center text-center max-w-[16rem] m-auto'>
              {/* The ability to generate more apps in less time reduces costs */}
              Efficiency gains from faster development leads to cost reductions.
            </p>
          </div>

          <div className='card-body font-DM-Sans xs:w-8/12 sm:w-11/12 border border-[#F5F5F5] rounded-lg m-4 justify-self-center hover:shadow-xl shadow-card1 sm:shadow-none'>
            <div className='rounded-full xs:w-16 xs:h-16 lg:w-[5.313rem] lg:h-[5.313rem] self-center m-8'>
              <img className='' src={FasterTransformation} alt='' />
            </div>
            <h1 className='xs:text-base lg:text-xl text-[#424242] self-center text-center'>
              Faster Transformation
            </h1>
            <p className='xs:text-xs lg:text-base text-[#838381] self-center text-center max-w-[16rem] m-auto'>
              {/* Better equipped to quickly adapt and respond to fast-changing business conditions */}
              Low code platforms enable swift responses to markets and
              regulations, boosting competitiveness through seamless integration
              and compliance.
            </p>
          </div>

          <div className='card-body font-DM-Sans xs:w-8/12 sm:w-11/12 border border-[#F5F5F5] rounded-lg m-4 justify-self-center hover:shadow-xl shadow-card1 sm:shadow-none'>
            <div className='rounded-full xs:w-16 xs:h-16 lg:w-[5.313rem] lg:h-[5.313rem] self-center m-8'>
              <img className='' src={HighProductivity} alt='' />
            </div>
            <h1 className='xs:text-base lg:text-xl text-[#424242] self-center text-center'>
              High Productivity
            </h1>
            <p className='xs:text-xs lg:text-base text-[#838381] self-center text-center max-w-[16rem] m-auto'>
              {/* We present you a proposal and discuss nitty- gritty like */}
              Rapid MVP and prototype development is made possible by low code,
              which reduces time to market and increases overall organizational
              efficiency.
            </p>
          </div>

          <div className='card-body font-DM-Sans xs:w-8/12 sm:w-11/12 border border-[#F5F5F5] rounded-lg m-4 justify-self-center hover:shadow-xl shadow-card1 sm:shadow-none'>
            <div className='rounded-full xs:w-16 xs:h-16 lg:w-[5.313rem] lg:h-[5.313rem] self-center m-8'>
              <img className='' src={IncreasedProfitability} alt='' />
            </div>
            <h1 className='xs:text-base lg:text-xl text-[#424242] self-center text-center'>
              Increased Profitability
            </h1>
            <p className='xs:text-xs lg:text-base text-[#838381] self-center text-center max-w-[16rem] m-auto'>
              {/* Improve the profitability and drive business growth */}
              By reducing costs, accelerating the launch of new products, and
              responding quickly to market changes, low code promotes
              profitability and boosts sales.
            </p>
          </div>

          <div className='card-body font-DM-Sans xs:w-8/12 sm:w-11/12 border border-[#F5F5F5] rounded-lg m-4 justify-self-center hover:shadow-xl shadow-card1 sm:shadow-none'>
            <div className='rounded-full xs:w-16 xs:h-16 lg:w-[5.313rem] lg:h-[5.313rem] self-center m-8'>
              <img className='' src={ImprovesUserExperience} alt='' />
            </div>
            <h1 className='xs:text-base lg:text-xl text-[#424242] self-center text-center'>
              Improves User Experience
            </h1>
            <p className='xs:text-xs lg:text-base text-[#838381] self-center text-center max-w-[15.688rem] m-auto'>
              {/* It works wonders with speed and renders a superior customer experience */}
              Low code platforms optimize user experience with tailored,
              integrated apps, accessibility, cross-platform compatibility and
              speed.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}

export default WhyLowCode;

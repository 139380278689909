// Navbar Menu's Options

export const navLinks = [
  {
    id: "whylowcode",
    title: "Why Low Code",
  },
  {
    id: "scale",
    title: "Our Solutions",
  },
  {
    id: "platform",
    title: "Platforms",
  },
  // {
  //   id: "aboutus",
  //   title: "About Us",
  // },
  {
    id: "contactus",
    title: "Get In Touch",
    class: 'blue-btn lg:text-base sm:text-xs lg:px-6 sm:px-4 lg:py-3 sm:py-2'
  },
];
import React from 'react'
import Marquee from "react-fast-marquee";
import quixy from '../assets/Platforms/quixy.svg'
import nintex from '../assets/Platforms/nintex.svg'
import pega from '../assets/Platforms/pega.svg'
import kissflow from '../assets/Platforms/kissflow.svg'
import wavemaker from '../assets/Platforms/wavemaker.svg'
import joget from '../assets/Platforms/joget.svg'
import quickbase from '../assets/Platforms/quickbase.svg'
import zudy from '../assets/Platforms/zudy.svg'
import caspio from '../assets/Platforms/caspio.svg'
import servicenow from '../assets/Platforms/servicenow.svg'

function Marqtwo() {
  return (
    <div className="">
        <Marquee speed='20' gradientColor='transparent'>
          <img className='p-4 w-[12rem] h-auto' src={quixy} alt="" />
          <img className='p-4 w-[12rem] h-auto' src={nintex} alt="" />
          <img className='p-4 w-[12rem] h-auto' src={pega} alt="" />
          <img className='p-4 w-[12rem] h-auto' src={kissflow} alt="" />
          <img className='p-4 w-[12rem] h-auto' src={wavemaker} alt="" />
          <img className='p-4 w-[12rem] h-auto' src={joget} alt="" />
          <img className='p-4 w-[12rem] h-auto' src={quickbase} alt="" />
          <img className='p-4 w-[12rem] h-auto' src={zudy} alt="" />
          <img className='p-4 w-[12rem] h-auto' src={caspio} alt="" />
          <img className='p-4 w-[12rem] h-auto' src={servicenow} alt="" />
        </Marquee>
    </div>
  )
}

export default Marqtwo
/** @format */

import React, { useRef } from "react";
import Started from "../assets/Started.svg";
import Started2 from "../assets/Started2.svg";
import xsvector from "../assets/xsvector.png";
import xsdecorator from "../assets/xsdecorator.png";
import emailjs from "@emailjs/browser";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function ContactUs2() {
  const form = useRef();

  const showToast = (type, message) => {
    toast[type](message, {
      position: "bottom-center", // Set the position to bottom center
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    });
  };

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_8eiag5d",
        "template_bl5zj2o",
        form.current,
        "-5AnRAh0SoSG241sN"
      )
      .then(
        (result) => {
          console.log(result.text);
          showToast("success", "Message sent successfully!");
        },
        (error) => {
          console.log(error.text);
          showToast("error", "Error sending message. Please try again.");
        }
      );
    e.target.reset();
  };

  return (
    <section id='contactus' className='w-full max-w-[120rem] pt-1 m-auto'>
      <div className='text-center bg-gradient-started'>
        <div className='sm:flex relative'>
          <img
            className='xs:hidden sm:block absolute left-0 mt-2'
            src={Started}
            alt=''
          />
          <img
            className='xs:hidden sm:block absolute right-0 '
            src={Started2}
            alt=''
          />
          {/* for small screen */}
          <img
            className='xs:block sm:hidden absolute left-0 mt-2'
            src={xsdecorator}
            alt=''
          />
          <img
            className='xs:block sm:hidden absolute right-0 '
            src={xsvector}
            alt=''
          />
        </div>

        <h1 className='xs:text-2xl xs:leading-8 xs:text-center xs:pt-12 xs:mb-3 sm:text-4xl sm:leading-8 sm:text-center sm:mt-20 sm:mb-6 font-Gilroy-Bold text-5xl text-[#313131] '>
          Ready to get started?
        </h1>
        <p className='xs:text-sm xs:font-[400] xs:leading-5 xs:flex xs:place-content-center xs:max-w-[21.875rem] sm:max-w-[41.625rem] font-DM-Sans text-lg text-[#425466] leading-7 m-auto'>
          Products on online services or over the Internet. Electronic commerce
          draws on technologies such as mobile commerce application
        </p>
        <div className='m-auto'>
          {/* <form className="xs:pt-8 sm:pt-12 pb-40 flex sm:block xs:px-[2.813rem] justify-center">
                        <input
                            type="email"
                            name=""
                            id=""
                            placeholder="Enter your email address"
                            className="xs:px-4 xs:w-[15.625rem] xs:h-12 sm:px-4 sm:w-80 sm:h-16 input-group text-[#A5A5A5] bg-[#fff] font-DM-Sans text-base font-medium leading-5 sm:inline-flex started"
                        />
                        <button
                            className="xs:h-10 xs:w-[5.938rem] started btn bg-[#5E60D8] sm:h-16 border-none rounded-none inline-flex sm:max-w-36"
                            type="submit"
                        >
                            <span className='capitalize text-[#fff] font-DM-Sans font-medium text-base leading-5'>Sign Up</span>
                        </button>
                    </form> */}

          {/* NEW CODE WITH EMAIL IMPLEMENTATION  */}

          <form
            ref={form}
            onSubmit={sendEmail}
            className='sm:p-20 xs:p-10 m-auto'
          >
            <div className='pt-0 mb-3'>
              <input
                type='text'
                placeholder='Your name'
                name='user_name'
                className='focus:outline-none focus:ring relative w-full px-3 py-3 text-sm text-gray-600 placeholder-gray-400 bg-white !border-0 rounded shadow outline-none'
                // required
              />
            </div>
            <div className='pt-0 mb-3'>
              <input
                type='email'
                placeholder='Email'
                name='user_email'
                className='focus:outline-none focus:ring relative w-full px-3 py-3 text-sm text-gray-600 placeholder-gray-400 bg-white !border-0 rounded shadow outline-none'
                required
              />
            </div>

            <div className='pt-0 mb-3'>
              <input
                type='text'
                // placeholder="Please enter mobile number with country code."
                name='company_name'
                className='focus:outline-none focus:ring relative w-full px-3 py-3 text-sm text-gray-600 placeholder-gray-400 bg-white !border-0 rounded shadow outline-none'
                placeholder='Company Name'
                required
              />
            </div>
            <div className='pt-0 mb-3'>
              <input
                type='tel'
                // placeholder="Please enter mobile number with country code."
                name='number'
                className='focus:outline-none focus:ring relative w-full px-3 py-3 text-sm text-gray-600 placeholder-gray-400 bg-white !border-0 rounded shadow outline-none'
                pattern='^\+\d{1,3}(?:[0-9] ?){6,14}[0-9]$'
                placeholder='e.g., +911234567890'
                required
              />
            </div>
            <div className='pt-0 mb-3'>
              <select
                style={{ background: "white" }}
                placeholder='Select Services'
                name='services'
                className='focus:outline-none focus:ring relative w-full px-3 py-3 text-sm text-gray-600 placeholder-gray-400 !bg-white !border-0 rounded shadow outline-none'
                required
              >
                <option>Select Services</option>
                <option value='Custom Software Development'>
                  Custom Software Development
                </option>
                <option value='Low-Code Software Development'>
                  Low-Code Software Development
                </option>
                <option value='Resource Outsourcing'>
                  Resource Outsourcing
                </option>
                <option value='Technical Support'>Technical Support</option>

                {/* Add more options as needed */}
              </select>
            </div>
            <div className='pt-0 mb-3'>
              <textarea
                placeholder='Your message'
                name='message'
                className='focus:outline-none focus:ring relative w-full px-3 py-3 text-sm text-gray-600 placeholder-gray-400 bg-white !border-0 rounded shadow outline-none'
                required
              />
            </div>
            <div className='pt-0 mb-3'>
              <button
                className=' active:bg-blue-600 hover:shadow-lg focus:outline-none px-6 py-3 mb-1 mr-1 text-sm font-bold text-[black] uppercase transition-all duration-150 ease-linear bg-blue-500 rounded shadow outline-none !btn !bg-[#5E60D8]'
                // className="xs:h-10 xs:w-[5.938rem] started btn bg-[#5E60D8] sm:h-16 border-none rounded-none inline-flex sm:max-w-auto"
                value='Send'
                type='submit'
              >
                <span className='capitalize text-[#fff] font-DM-Sans font-medium text-base leading-5'>
                  Send a message
                </span>
              </button>
            </div>
          </form>
          <ToastContainer
            position='bottom-center' // Set the default position
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />
        </div>
      </div>
    </section>
  );
}

export default ContactUs2;
